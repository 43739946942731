import { Button } from '@/components/ui/button';
import { getItemCount } from '@/app/[lng]/game/utils';
import { get } from 'lodash-es';
import useGameItemsMap from '@/app/[lng]/game/hooks/useGameItemsMap';
import useImpactOccurred from '@/hooks/useVibration';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { userInfoAtom, userGameStatus } from '@/store/userInfo';
import { currentPotionAtom } from '@/store/runtime';
import { useAtom } from 'jotai';
import { Subtract } from '@/utils/decimal/operations';
import { useMutation } from '@tanstack/react-query';
import { EventId, gameStart, type UserMetaModel } from '@/apis/game';
import { useRouter } from 'next/navigation';
import { useTranslations, useLocale } from 'next-intl';
import { useToast } from '@/components/ui/use-toast';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogTitle,
} from '@/components/ui/alert-dialog';
import { BaseInteractive } from '@/components/ui/BaseInteractiveButton';
import LocalizedLink from '@/components/ui/LocalizedLink';
import { UserGameStatusDTO } from '@/apis/login';
import { fetchPotionDetail, PotionDetailResponse, PotionItem } from '@/apis/potion';
import { DifficultyDisplay } from '@/components/lobby/DifficultyDisplay';
import { getPublicAsset } from '@/utils/getPublicAsset';
import Image from 'next/image';
import { TicketDialog } from '@/app/[lng]/game/components/TicketDialog';
import formatMoney from '@/utils/decimal/formatMoney';
import { formatLargeNumber } from '@/utils/number/formatLargeNumber';
import formatNumber from '@/utils/number/formatter';
import StartGameUsePotion from '@/components/Potion/StartGameUsePotion';
import { cn } from '@/lib/utils';
import { track } from '@/utils/thinkdata/track';
import { currentMonthPassAtom } from '@/store/userInfo';
import { monthlyPassConfig } from '@/constants/monthlyPass';


const usePlayGame = () => {
  const [isContinueOpen, setIsContinueOpen] = useState(false);
  const [currentMonthPass] = useAtom(currentMonthPassAtom);
  const gameItemsMap = useGameItemsMap();
  const [impactOccurred] = useImpactOccurred('heavy');
  const [userGame, setUserGameStatus] = useAtom(userGameStatus);
  const [isGamePageLoading, setIsGamePageLoading] = useState(false);
  const { journey } = userGame;
  const locale = useLocale();
  const t = useTranslations();
  const canFreePlay: boolean = useMemo(() => {
    const freeTimes = get(userGame, 'playFreeTimes', '0') || '0';
    const playTimes = get(userGame, 'playTimes', '0') || '0';
    return Number(Subtract(freeTimes, playTimes)) > 0;
  }, [userGame]);
  const lastFreeTimes = useMemo(() => {
    const freeTimes = get(userGame, 'playFreeTimes', '0') || '0';
    const playTimes = get(userGame, 'playTimes', '0') || '0';
    return Math.max(Subtract(freeTimes, playTimes), 0);
  }, [userGame]);
  const { toast } = useToast();
  const ticket = getItemCount(get(gameItemsMap, 'ticket.haveCount', 0));
  const [userInfo, setUserInfo] = useAtom(userInfoAtom);
  const [currentPotion] = useAtom(currentPotionAtom);

  const handleGameStart = () => {
    impactOccurred();
    const hasMonthlyPassFreeEntry = currentMonthPass && monthlyPassConfig[currentMonthPass.name]?.ticket;
    console.log('hasMonthlyPassFreeEntry',hasMonthlyPassFreeEntry);

    if(hasMonthlyPassFreeEntry){
      setIsContinueOpen(true);
      return;
    }

    if (!canFreePlay && ticket === '0') {
      setTicketDialogShow(true);
      return;
    }
    setIsContinueOpen(true);
  };

  const handleGameNextlevel = (callback?: (data: UserMetaModel) => void) => {
    goToGame(
      { eventId: EventId.start, potionId: currentPotion?.potionId },
      {
        onSuccess: (data) => {
          callback?.(data);
        },
      },
    );
  };

  const hasSufficientBalance = useMemo(
    () => Number(journey?.nextPrice) < Number(userInfo?.gold),
    [journey, userInfo],
  );

  const canEnterNextLevel = useMemo(() => {
    return canFreePlay || (ticket !== '0' && hasSufficientBalance);
  }, [canFreePlay, ticket, hasSufficientBalance]);

  const handleContinueGameStart = () => {
    setTicketDialogShow(false);
    setIsContinueOpen(true);
  };

  const linkRef = useRef<HTMLAnchorElement>(null);

  const goToGamePage = () => {
    linkRef.current?.click();
  };

  const { mutate: goToGame, isPending: isFetchGameStartLoading } = useMutation({
    mutationFn: gameStart,
    onSuccess: (data) => {
      setIsGamePageLoading(true);
      setUserInfo({ ...data.userModel });
      setUserGameStatus({ ...data.userGameStatusDTO });
      setTimeout(() => {
        goToGamePage();
      }, 0);

      track('challenge_start', {
        isFreePlay: canFreePlay,
        ticket: getItemCount(get(gameItemsMap, 'ticket.haveCount', 0)),
        task_id: journey.currentLevel,
        cost_coin: journey?.nextPrice,
      });
    },
    onError: ({ data }: any) => {
      toast({
        description: data.status.message,
        duration: 3000,
      });
    },
  });

  const [ticketDialogShow, setTicketDialogShow] = useState(false);

  const isGameLoading = useMemo(() => {
    return isFetchGameStartLoading || isGamePageLoading;
  }, [isFetchGameStartLoading, isGamePageLoading]);

  const PlayGameButton = (
    <div>
      <Button
        onClick={handleGameStart}
        className={`${isGameLoading ? 'pointer-events-none ' : ''}block bg-[#E2F43E]  pt-[0.3rem] pb-[0.5rem] px-[30px] select-none   min-h-[3.5rem]`}
        shadowColor="#858F23"
      >
        <div className="flex-row justify-center items-center">
          <span
            className={
              'font-bold stroke-chocolate text-white font-mono text-xl stroke-black text-nowrap'
            }
          >
            {isGameLoading ? 'Starting...' : t('play-game')}
          </span>
          <div className="text-[#6e5c20] text-xs">
            <span className={'font-bold'}>
              {lastFreeTimes}/{userGame.playFreeTimes} {t('free-attempts')}
            </span>
          </div>
        </div>
      </Button>

      <ContinueDialog
        isOpen={isContinueOpen}
        onClose={() => setIsContinueOpen(false)}
        onContinue={() => {
          !isGameLoading &&
            goToGame(
              { eventId: EventId.start, potionId: currentPotion?.potionId },
              // {
              //   onSuccess: () => {
              //     goToGamePage();
              //   },
              // },
            );
        }}
        journey={journey}
        userGame={userGame}
        userInfo={userInfo}
        hasSufficientBalance={hasSufficientBalance}
        isPending={isGameLoading}
        t={t}
        tickets={getItemCount(get(gameItemsMap, 'ticket.haveCount', 0))}
      />
      <TicketDialog
        show={ticketDialogShow}
        onClose={() => setTicketDialogShow(false)}
        handleContinueGameStart={handleContinueGameStart}
      />
      <LocalizedLink href={`/game`} prefetch={true} ref={linkRef} />
    </div>
  );

  return {
    PlayGameButton,
    handleGameStart,
    handleGameNextlevel,
    canEnterNextLevel,
  };
};

const ContinueDialog = ({
  isOpen,
  onClose,
  onContinue,
  journey,
  userGame,
  userInfo,
  isPending,
  tickets,
  t,
  hasSufficientBalance,
}: {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  journey: UserGameStatusDTO['journey'];
  userGame: any;
  userInfo: any;
  isPending: boolean;
  tickets: string;
  t: any;
  hasSufficientBalance: boolean;
}) => {
  const InfoItem = ({ label, value, valueClass = "", icon, iconWidth = 20, iconHeight = 20, children = null }) => (
    <div className="text-nowrap">
      <span className="text-right text-[#222] inline-block">{label}:</span>
      {
        children ? (
          children
        ) : (
        <span className="space-x-1 ml-1">
          {label === 'Balance' ? (
            <span className={cn("inline-block", valueClass)}>{formatLargeNumber(value)}</span>
          ) : (
            <span className={cn("inline-block", valueClass)}>{value}</span>
          )}
          {icon && (
            <Image
              className="inline-block align-middle"
              width={iconWidth}
              height={iconHeight}
              src={icon}
              alt="icon"
            />
          )}
        </span>
        )
      }
    </div>
  );

  const InfoSection = ({
    items,
    showTips = false,
    pinaRareProb,
    pinaRareProbPotion,
    potion = null,
  }: {
    items: Array<any>;
    showTips: boolean;
    pinaRareProb: string;
    pinaRareProbPotion: string;
    potion?: PotionItem;
  }) => {
    const labelOrder = ['Cost', 'Balance', 'Difficulty', 'Reward'];
    const levelText =
      journey.currentLevel < 6 ? (
        <p>
          Level <span className={'text-[#FAD54A]'}>{journey.currentLevel}</span>{' '}
          now, <span className={'text-[#7FC272]'}>beat it to win Pina!</span>{' '}
        </p>
      ) : (
        <p className={'whitespace-nowrap'}>
          The last level, beat it to win{' '}
          <span className={'text-[#FF9900]'}>Rare Pina!</span>
        </p>
      );
    return (
      <div className={'flex flex-col bg-[#DDC26E30] rounded-xl py-3 px-3 text-[14px]'}>
        <div className="text-base stroke-black text-center font-bold mb-4 font-comicbd text-[16px]">
          {levelText}
        </div>
        <div
          className={
            'grid grid-cols-2 gap-y-2 w-full text-black flex flex-row justify-between'
          }
        >
          {
            labelOrder.map((o) => {
              const item = items.find(item => item.label === o);
              if (!item) return null;
              return (
                <InfoItem key={o} {...item} />
              );
            })
          }
        </div>
        <div className="mt-2">
          {
            !!potion?.potionId ? (
              <InfoItem
                key={"Potion"}
                label="Potion"
                valueClass="mt-6 text-[#FAD54A] stroke-black"
                value={`${potion?.name} LV${potion?.potionId}`}
                icon={getPublicAsset(`/images/potions/v1/lv${potion.potionId}.png`)}
                iconWidth={28}
                iconHeight={28}
              >
                <span className="relative space-x-1 ml-1">
                  <span
                    className="text-[#FAD54A] stroke-black">
                    {potion?.name} LV{potion?.potionId}
                  </span>
                  <Image
                    className="absolute right-[-32px] top-[-6px]"
                    src={getPublicAsset(`/images/potions/v1/lv${potion.potionId}.png`)}
                    alt="potion"
                    width={24}
                    height={24}
                  />
                </span>
              </InfoItem>
            ) : null
          }
        </div>
        <div className="mt-2">
          <InfoItem
            key={"Pina Rare Prob"}
            label="Pina Rare Prob"
            valueClass="mt-4 text-[#FAD54A] stroke-black"
            value=""
            icon={null}
          >
            {
              !!potion?.potionId ? (
                <span className="space-x-1 ml-1">
                  <span className="text-white stroke-black"> {pinaRareProb} </span>
                  <Image className="w-[13px] h-[9px] inline mr-1" alt="arrow" src={getPublicAsset('/images/potions/arrow.png')} width={24} height={24} />
                  <span className="text-[#FAD54A] stroke-black"> {pinaRareProbPotion} </span>
                  <Image className="w-[12px] h-[14px] mb-1 inline" alt="up" src={getPublicAsset('/images/potions/up.png')} width={24} height={24} />
                  <Image className="w-[12px] h-[14px] mb-1 inline" alt="up" src={getPublicAsset('/images/potions/up.png')} width={24} height={24} />
                  <Image className="w-[12px] h-[14px] mb-1 inline" alt="up" src={getPublicAsset('/images/potions/up.png')} width={24} height={24} />
                </span>
              ) : (
                <span className="space-x-1 ml-1">
                  <span className="text-white stroke-black"> {pinaRareProb} </span>
                </span>
              )
            }
          </InfoItem>
        </div>
      </div>
    );
  };
  const coinIcon = getPublicAsset('/images/lobby/coins.png');

  const gameInfo = [
    {
      label: t('message.difficulty'),
      value: (
        <DifficultyDisplay difficultyLevel={String(journey.currentLevel)} />
      ),
    },
    { label: t('message.balance'), value: userInfo.gold, icon: coinIcon },
    {
      label: t('message.cost'),
      value: formatMoney(journey?.nextPrice),
      icon: coinIcon,
    },
    {
      label: t('message.reward'),
      value: formatMoney(journey.nextRewards),
      icon: coinIcon,
    },
  ];
  const router = useRouter();
  const locale = useLocale();
  const [currentPotion] = useAtom(currentPotionAtom);
  const [selectPotion, setSelectPotion] = useState(true);
  const [potion, setPotion] = useState(null);
  const [pinaRareProb, setPinaRareProb] = useState('');
  const [pinaRareProbPotion, setPinaRareProbPotion] = useState('');

  return (
    <AlertDialog open={isOpen}>
      <AlertDialogContent
        enable3DBorder
        shadowColor="#EE9B40"
        className={
          'relative flex flex-col w-11/12 bg-[#FFF4DA] text-white !p-0 border-none outline-none shadow-none font-comicbd'
        }
      >
        <BaseInteractive onInteractiveClick={onClose}>
          <div className="closeButton absolute top-[-18px] right-[-18px] w-[40px] h-[40px] z-20 active:translate-y-0.5 active:translate-x-0.5">
            <Image
              fill
              src={getPublicAsset('/images/lobby/close.png')}
              alt="close"
            />
          </div>
        </BaseInteractive>
        <AlertDialogTitle className="w-auto bg-[#F6C334] py-2 border-b-2 border-black text-lg text-white stroke-black text-center">
          <span className={'text-xl leading-6'}>
            {t('message.trip', {
              index: journey?.index,
            })}
          </span>
        </AlertDialogTitle>
        <div className="px-3">
          <div className={cn(false && selectPotion ? "hidden" : "")}>
            <InfoSection
              items={gameInfo}
              showTips={true}
              potion={currentPotion}
              pinaRareProb={pinaRareProb}
              pinaRareProbPotion={pinaRareProbPotion}
            />
          </div>
          <div className={cn(true || selectPotion ? "" : "hidden")}>
            <div className="flex flex-col text-base items-center justify-center text-white stroke-black my-2">
              <span> Use potion to boost Pina Rare Prob to </span>
              <span className="text-[#FAD54A] stroke-black">
                {pinaRareProbPotion ? pinaRareProbPotion : "pending..."}
              </span>
            </div>
            <StartGameUsePotion
              onPotionDetailResponse={(data) => {
                setPinaRareProb(`${formatNumber(data?.xprob * 100, { precision: 2 })}%`);
                setPinaRareProbPotion(`${formatNumber(data?.potionDetail.pinaRareProb * 100, { precision: 2 })}%`);
              }}
            />
          </div>
          <AlertDialogFooter className="!flex my-2">
            {
              false && selectPotion ? (
                <AlertDialogAction
                  className="bg-[#FFCB38]"
                  /* @ts-ignore */
                  shadowColor="#A37F35"
                  onClick={() => { setSelectPotion(false) }}
                >
                  {'Continue'}
                </AlertDialogAction>
              ) :
                hasSufficientBalance ? (
                  <>
                    {
                      // <AlertDialogCancel
                      //   onClick={() => {
                      //     setSelectPotion(true);
                      //   }}
                      // >
                      //   {'Prev'}
                      // </AlertDialogCancel>
                    }
                    <AlertDialogAction
                      className="bg-[#FFCB38]"
                      /* @ts-ignore */
                      shadowColor="#A37F35"
                      onClick={onContinue}
                    >
                      {isPending ? 'Launching...' : 'Continue'}
                    </AlertDialogAction>
                  </>
              ) : (
                <AlertDialogAction
                  className=""
                  onClick={() => router.push(`${locale}/earn`)}
                >
                  {t('need-more-pine-coin')}
                </AlertDialogAction>
              )
            }
          </AlertDialogFooter>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default usePlayGame;
